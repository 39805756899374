
@media (max-width: 486px) {
    .managenabar {
        width: 100% !important;
        margin-top: 6px !important;
         
    
    }

    .widthStyle {
        margin: 0px !important;
        margin-top: 6px !important;
       
    }

    .well__wellStylesgoals {
        font-size: 30px !important;
        margin-left: 10 !important;
    }

    .imagehide {
        display: none !important;
    }
    .buttonmediamobile{
        width: 200px !important;
    }
.decreasefont{
    font-size: 17px !important;
    margin-left: -13px !important
}
.middletext{
    font-size: 13px !important;
    white-space: normal !important; 
    word-break: break-word !important; 
    line-height: 1.5 !important;
    margin-left: -13px !important;
}
.startqustmedia{
    width: 286px !important;
}
.heightdown{
    height:260px !important
}
.fivefive{
    width: 95px !important;
  height: 130px !important;
}
.bottomtext{
    font-size: 20px !important;
    margin: 87px 0px 0px !important;
}
.aboutduiring{
    width: 90% !important;
}
.marginmange{
    margin-left: 35px !important;
}
.backstylemobile{
    margin-left: -33px !important;
}
.fontinthebox{
    font-size: 15px !important;
}
.paddinremove{
    padding: 0rem !important;

}
.font{
    font-size: 22px !important;
}


}
@media (max-width: 375px) {
    .currentqustionstyle{
        font-size: 14px !important;
    }
    .bottomtext{
        font-size: 16px !important;
        margin: 87px 0px 0px !important;
    }
    .startqustmedia{
        width: 250px !important;
    }
    .minutsstyele{
        font-size: 20px !important;
    }
   
    .widthStyle {
      
        width: 91% !important;
        margin-top: 6px !important;
    }
    .decreasefont{
        font-size: 14px !important;
        margin-left: -13px !important;
        margin-top: -1rem !important;
    }
    .middletext{
        font-size: 11px !important;
        white-space: normal !important; 
        word-break: break-word !important; 
        line-height: 1.5 !important;
        margin-left: -13px !important;
        margin-top: -1rem !important;
    }

}
@media (max-width: 820px) {  
     .imagehide {
    display: none !important;
}
    .currentqustionstyle{
        font-size: 14px !important;
    }
    .bottomtext{
        font-size: 19px !important;
        margin: 87px 0px 0px !important;
    }
    .startqustmedia{
        width: 250px !important;
    }
    .minutsstyele{
        font-size: 20px !important;
    }
  
    .widthStyle {
      margin-right: 13px !important;
  margin-left: 13px !important;
  min-height:88vh !important
 
    } 
   
  

}
@media (min-width: 900px) and (max-width: 1086px) {  
    .widthStyle {
       height: 58vh !important;
    } 
 }