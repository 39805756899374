body {
   background-color:#f0f0f0 !important;
  }


  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
     display: none !important;
  }
  .css-19kzrtu {
    padding: 0 !important;
  }
  /* .css-heg063-MuiTabs-flexContainer {
    display: flex !important;
    max-height: 47px !important;
  } */
  .vertical-text-container {
    display: flex;
    align-items: center;
  }
  
  
  .vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #00A2FF;
    font-size: 16px;
    font-family: Arial, sans-serif;
    padding: 5px;
    margin-left: 10px;
    position: relative;
    transform: rotate(180deg);
    left:12px
  }
  .buttonStyle{
        padding: 0.5rem;
        width: 320px;
        border-radius: 8px;
        border: none;
        background: linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%);
        position: relative;
        cursor: pointer; /* Optional: to show pointer cursor on hover */
        z-index: 2;

  }
  .buttonStylesss{
    padding: 0.5rem;
    width: "auto";
    height:44px;
    border-radius: 8px;
    border: none;
    background: linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%);
    position: relative;
    cursor: pointer;
    z-index: 2;

}
  .css-175oi2r{
    width: 100% !important;
    max-width: 100% !important;
  }
  .defaultbtn{
    padding: 0.4rem;     
    width: 278px;         
    border-radius: 8px;     
    border: none;           
    background: linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)
  }
  .activebtn{
    padding: 0.4rem;   
    width: 278px;          
    border-radius: 8px;   
    border: none;          
    background: linear-gradient(99.09deg, #004463 2.64%, #00A7D1 100%)
  }
  .vertical-text::before {
    content: '';
    position: absolute;
    left: -10px; /* Adjust to position dotted line */
    top: 0;
    bottom: 0;
  }
  

  @media (max-width: 486px){
    .css-jpln7h-MuiTabs-scroller {
  
    overflow: auto !important;
  }
    .well__wellStylesgoals {
      font-size: 30px !important;
      margin-left: 10 !important;
    }
 
  
    .imagehide {
      display: none !important;
     }
    .marginset{
      margin-top: 10px !important;
    }
    .fontsizepresent{
      font-size: 26px !important
    }
    .buttonStylesss{
      padding: 0.5rem;
      height:44px;
      border-radius: 8px;
      border: none;
      background: linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%);
      position: relative;
      cursor: pointer;
      z-index: 2;
   }
  .setfontsize{
    font-size: 14px !important;
  }
    .flexcolumn {
     flex-direction: column !important;
     align-items: self-start !important;
     
  }
    .imgeStyle {
      display: none !important;
  }
      .buttinmaringset{
        margin-top: 14px !important;
   }
      .setFont{
        font-size: 28px !important;
        text-align: left !important;
  }
  .managenabar{
 
       width: 97% !important
   }

  }

  @media (max-width: 820px){
    .imgeStyle {
      position: absolute !important;
      top: 110px !important;
      right: 0px !important;
     }
    .imagehide {
     
     position: absolute !important;
  top: 107px !important;
  right: 0px !important;
    }
    .cardsizemagane{
      width: 353px !important;
    }
    .cardsizemaganethisweek{
      width: 345px !important;
    }

  }
  @media (max-width: 775px) {
    .cardsizemaganethisweek {
        width: 328px !important;
    }
  }
  @media (max-width: 380px){
  
    .cardsizemaganethisweek{
      width: 300px !important;
    }


  }
 
 

  
 