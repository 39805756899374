
@font-face{
    font-family: 'Chromatica-Regular';
    src: url('./fontlist/Chromatica/Chromatica-Regular.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Regular';
    src: url('./fontlist/Chromatica/Chromatica-Regular.eot') format('eot'),
}
@font-face{
    font-family: 'Chromatica-Regular';
    src: url('./fontlist/Chromatica/Chromatica-Regular.woff') format('woff'),
}
@font-face{
    font-family: 'Chromatica-Regular';
    src: url('./fontlist/Chromatica/Chromatica-Regular.woff2') format('woff2'),
}


@font-face{
    font-family: 'Chromatica-Bold';
    src: url('./fontlist/Chromatica/Chromatica-Bold.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Bold';
    src: url('./fontlist/Chromatica/Chromatica-Bold.eot') format('eot'),
}
@font-face{
    font-family: 'Chromatica-Bold';
    src: url('./fontlist/Chromatica/Chromatica-Bold.woff') format('woff'),
}
@font-face{
    font-family: 'Chromatica-Bold';
    src: url('./fontlist/Chromatica/Chromatica-Bold.woff2') format('woff2'),
}



@font-face{
    font-family: 'Chromatica-Black';
    src: url('./fontlist/Chromatica/Chromatica-Black.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Black';
    src: url('./fontlist/Chromatica/Chromatica-Black.eot') format('eot'),
}
@font-face{
    font-family: 'Chromatica-Black';
    src: url('./fontlist/Chromatica/Chromatica-Black.woff') format('woff'),
}
@font-face{
    font-family: 'Chromatica-Black';
    src: url('./fontlist/Chromatica/Chromatica-Black.woff2') format('woff2'),
}


@font-face{
    font-family: 'Chromatica-Thin';
    src: url('./fontlist/Chromatica/Chromatica-Thin.woff2') format('woff2'),
}
@font-face{
    font-family: 'Chromatica-Thin';
    src: url('./fontlist/Chromatica/Chromatica-Thin.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Thin';
    src: url('./fontlist/Chromatica/Chromatica-Thin.eot') format('eot'),
}


@font-face{
    font-family: 'Chromatica-Medium';
    src: url('./fontlist/Chromatica/Chromatica-Medium.eot') format('eot'),
}
@font-face{
    font-family: 'Chromatica-Medium';
    src: url('./fontlist/Chromatica/Chromatica-Medium.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Medium';
    src: url('./fontlist/Chromatica/Chromatica-Medium.woff2') format('woff2'),
}
@font-face{
    font-family: 'Chromatica-Medium';
    src: url('./fontlist/Chromatica/Chromatica-Medium.woff') format('woff'),
}



@font-face{
    font-family: 'Chromatica-Light';
    src: url('./fontlist/Chromatica/Chromatica-Light.eot') format('eot'),
}
@font-face{
    font-family: 'Chromatica-Light';
    src: url('./fontlist/Chromatica/Chromatica-Light.ttf') format('truetype'),
}
@font-face{
    font-family: 'Chromatica-Light';
    src: url('./fontlist/Chromatica/Chromatica-Light.woff') format('woff'),
}
@font-face{
    font-family: 'Chromatica-Light';
    src: url('./fontlist/Chromatica/Chromatica-Light.woff2') format('woff2'),
}